import React from "react"
import { Formik, Form } from "formik"
import * as yup from "yup"
import styled from "styled-components"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"

const FormStyle = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px !important;
  margin-left: -15px !important;
  min-width: 100%;
`

const UploadButtonStyle = styled(Button)`
  margin-bottom: 10px !important;
`
const FormComponent = props => {
  const uploadAvaliable = props.uploadText && props.uploadText.length > 0
  const uploadText = props.uploadText
  const initialValues = {
    names: "",
    email: "",
    phone: "",
  }

  const validationSchema = yup.object().shape({
    names: yup.string().strict(true).required("Задължително поле."),
    email: yup
      .string()
      .email("Въведете валиден email адрес.")
      .required("Задължително поле."),
  })

  function onFileChange(e) {
    let files = e.target.files || e.dataTransfer.files
    if (!files.length) return
    loadFile(files[0])
  }
  function fileNameValidation(filePath) {
    // Allowing file type
    var allowedExtensions = /(\.pdf|\.jpg|\.jpeg|\.png)$/i

    if (!allowedExtensions.exec(filePath)) {
      return false
    }
    return true
  }

  var uploadedFile = null
  var fileName = null
  var genFileName = null
  const MAX_FILE_SIZE = 5000000
  const API_ENDPOINT =
    "https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/upload-to-s3"

  function loadFile(file) {
    let reader = new FileReader()
    fileName = file.name
    if (!fileNameValidation(fileName)) {
      return alert("Не валиден файл! Разрешените типове са PDF, JPEG, PNG.")
    }

    reader.onload = e => {
      console.log(e.target)
      if (
        !(
          e.target.result.startsWith("data:image/jpeg") ||
          e.target.result.startsWith("data:image/png") ||
          e.target.result.startsWith("data:application/pdf")
        )
      ) {
        return alert("Не валиден файл! Разрешените типове са PDF, JPEG, PNG.")
      }
      if (e.target.result.length > MAX_FILE_SIZE) {
        return alert("Размера на файла е прекалено голям - 5Mb максимум")
      }
      uploadedFile = e.target.result
    }
    reader.readAsDataURL(file)
  }

  async function uploadAttachment() {
    // Get the presigned URL
    const resp = await fetch(API_ENDPOINT + "?fileName=" + fileName)
    const response = await resp.json()
    genFileName = response.generatedFileName
    let binary = atob(uploadedFile.split(",")[1])
    let array = []
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    let blobData = new Blob([new Uint8Array(array)])
    const result = await fetch(response.uploadURL, {
      method: "PUT",
      body: blobData,
    })
    return result.url.split("?")[0]
  }

  async function onSubmit(fields) {
    // Upload the attachments
    if (uploadedFile) {
      await uploadAttachment()
    }
    // display form field values on success
    const toSend = {
      form: fields,
      page: window.location.href,
      fileName: genFileName,
    }
    const requestBody = JSON.stringify(toSend, null, 4)
    fetch("https://x1rjv9hn5l.execute-api.eu-west-1.amazonaws.com/contract", {
      method: "POST",
      body: requestBody,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(response => {
        console.log("Success:", requestBody)
        window.location.href = "/blagodarya-suobshtenie"
      })
      .catch(error => {
        console.error("Error:", error)
        window.location.href = "/blagodarya-za-porchkata?error=" + error
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, values, touched, handleChange }) => (
        <FormStyle className="contact-form row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <TextField
                  id="email"
                  name="email"
                  label="Email*"
                  value={values.email}
                  onChange={handleChange}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  variant="outlined"
                  style={{ margin: 8 }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <TextField
                  id="names"
                  name="names"
                  label="Имена*"
                  variant="outlined"
                  fullWidth
                  style={{ margin: 8 }}
                  value={values.names}
                  onChange={handleChange}
                  error={touched.names && Boolean(errors.names)}
                  helperText={touched.names && errors.names}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <TextField
                  id="phone"
                  name="phone"
                  label="Tелефон"
                  variant="outlined"
                  style={{ margin: 8 }}
                  value={values.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </div>
            </div>
          </div>
          {(() => {
            if (uploadAvaliable) {
              return (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12">
                      <UploadButtonStyle
                        variant="contained"
                        component="label"
                        color="primary"
                        fullWidth
                      >
                        <>{uploadText}</>

                        <input type="file" hidden onChange={onFileChange} />
                      </UploadButtonStyle>
                    </div>
                  </div>
                </div>
              )
            }

            return null
          })()}

          <div className="col-md-12">
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              type="submit"
            >
              Изпрати
            </Button>
          </div>
        </FormStyle>
      )}
    </Formik>
  )
}

export default FormComponent
