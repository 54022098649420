import React from "react"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { backgroundColors } from "../../../styled/colors"
import { richTextRenderOptions } from "../../../helpers/constants"

const CardBox = styled.div`
height: 100%;
`

const CardContainer = styled.div`
height: 100%;
  padding: 32px;
  border-radius: 5px;
`

const CardHeadContainer = styled.div`
  min-height: 112px;
  margin-bottom: 32px;
`

const CardHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }
`

const CardImg = styled.div`
  img {
    width: auto;
    max-width: auto;
    height: 112px;
    margin-bottom: 0;
    object-fit: contain;
  }
`

const CardBody = styled.div`
  --max-lines: 16;
  position: relative;
  overflow: hidden;
  min-height: 250px;
  max-height: calc(var(--lh) * var(--max-lines));
  text-overflow: ellipsis;
`

const Card = ({ title, iconUrl, content, backgroundColor = "gray" }) => {
  return (
    <CardBox>
      <CardContainer
        style={{
          backgroundColor: backgroundColor && backgroundColors[backgroundColor],
        }}
      >
        <CardHeadContainer>
          <CardHead>
            <h3 className="heading-3">{title}</h3>
            {iconUrl && (
              <CardImg>
                <img src={iconUrl} alt="icon" />
              </CardImg>
            )}
          </CardHead>
        </CardHeadContainer>
        <CardBody>{renderRichText(content, richTextRenderOptions)}</CardBody>
      </CardContainer>
    </CardBox>
  )
}

export default Card
