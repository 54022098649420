import React from 'react'
import { Link } from "gatsby"

import Image from "../../atoms/image/Image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './articleCard.css'


const ArticleCard = (props) => {
    const hasImage = props.hasImage
    const img = hasImage?getImage(props.image):null
    return (
        <Link className="article-card" to={props.to}>
            {hasImage?  <div className="article-image">
                <GatsbyImage image={img}  />
            </div> : ''}
          
            <div className={`${!hasImage ? "article-content-no-image" : ''} article-content`}>
                <p>{props.date}</p>
                <h4>{props.title}</h4>
                <p>{props.summary}</p>
            </div>
        </Link>
    )
}


export default ArticleCard