export const spacing = {
    'none': '0',
    '10': '0.125rem',
    '15': '0.1875rem',
    '25': '0.25rem',
    '50': '0.5rem',
    '60': '0.6rem',
    '75': '0.75rem',
    '100': '1.0rem',
    '125': '1.25rem',
    '150': '1.5rem',
    '175': '1.75rem',
    '200': '2.0rem',
    '250': '2.5rem',
    '275': '2.75rem',
    '300': '3.0rem',
    '350': '3.5rem',
    '400': '4.0rem',
    '500': '5.0rem',
    '550': '5.5rem',
    '575': '5.75rem',
    '600': '6.0rem',
    '650': '6.5rem',
    '800': '8.0rem',
  }