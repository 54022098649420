import React, { useState } from "react"
import styled from "styled-components"

import Image from "../../atoms/image/Image"
import Icon from "../../../images/chevron-right.png"
import IconHover from "../../../images/chevron-right--active.png"
import colors from '../../../styled/colors'

const IconStyle = styled.div`
  margin-right: 1rem;

  img {
    width: 13px;
    height: 1rem;
    margin: 0;
  }
`

const LinkHref = styled.a`
  margin: 0 !important;
  border: none !important;
  text-transform: uppercase;
`

const LinkWrapper = styled.div`
  cursor: pointer;

  &:hover {
    color: ${colors.secondary};
  }
`
const LinkBlock = ({ icon = false, link = "#", text = "", ...props }) => {
  const [hover, setHover] = useState(false)
  const toggleHover = active => {
    setHover(active)
  }
  return (
    <>
      {link && (
        <LinkWrapper onMouseEnter={toggleHover}>
          <LinkHref
            href={link}
            className="d-flex align-items-center"
            {...props}
          >
            {icon && (
              <IconStyle>
                {hover ? <Image src={IconHover} /> : <Image src={Icon} />}
              </IconStyle>
            )}
            <span> {text}</span>
          </LinkHref>
        </LinkWrapper>
      )}
    </>
  )
}

export default LinkBlock
