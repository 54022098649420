import React from "react"
import styled from "styled-components"
import Image from "../../atoms/image/Image"
import LogoIcon from "../../../images/hero-image-logo.png"

import "../../../styles/grid.css"
import "./hero-image.css"

const HeroSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  color: white;

  @media only screen and (max-width: 1024px) {
    height: 500px !important;
  }

  @media only screen and (max-width: 415px) {
    text-align: center;
    height: 400px !important;
  }

  &::before {
    @media only screen and (max-width: 415px) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }

  .hero-image__container {
    width: 100%;
  }

  .container {
    z-index: 2;
  }

  .content {
    display: inline-block;
  

    @media only screen and (min-width: 1024px) {
      max-width: 550px;
    }
  }

  h2,
  p {
    margin-bottom: 32px;
  }

`
const HeroBackgroundImage = styled(Image)`
  z-index: -1;
`

const HeroLogo = styled(Image)`
  width: auto;
  max-width: 192px;
  height: 45px;
  margin-bottom: 32px;

  @media only screen and (max-width: 415px) {
    display: none !important;
  }
`

const HeroImage = ({
  headline,
  backgroundImage = "",
  paragraph,
  actionText = "",
  actionLink = "",
  align,
  height,
  showLogo,
}) => {
  const isRightAlign = align === "right" || align == null ? true : false
  const isVisibleLogo = showLogo || showLogo != null ? showLogo : false
  let maxHeroHeight = "833px"

  switch (height) {
    case "xl":
      maxHeroHeight = "1072px"
      break
    case "m":
      maxHeroHeight = "833px"
      break
    case "sm":
      maxHeroHeight = "555px"
      break
    default:
      maxHeroHeight = "833px"
  }

  return (
    <HeroSection className="hero-image" style={{ height: maxHeroHeight }}>
      <div className={`hero-image__container d-flex align-items-center`}>
        <HeroBackgroundImage
          className="hero-image__background"
          image={backgroundImage}
          alt={headline || ""}
        />
        <div className="container">
          <div className="row">
            {isRightAlign && <div className="col-md-6"></div>}
            <div className="col-md-6">
              {headline && <div className="content"><h2>{headline}</h2></div>}
              {isVisibleLogo && <HeroLogo src={LogoIcon} />}     
              {paragraph && <div className="content"><p>{paragraph}</p></div>}
              {actionLink && (
                <a href={actionLink} className="button button--secondary">
                  {actionText}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default HeroImage
