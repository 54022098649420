import React from "react"
import styled from "styled-components"
import colors from "../../../styled/colors"
import CheckIcon from  "../../../images/check.png"

const UlStyle = styled.ul`
  list-style: none;
  list-style-position: inside;
  padding: 0;
  margin: 4rem 0 0;
`

const LiStyle = styled.li`
  position: relative;
  display: flex;
  line-height: 1;
  margin: 0 0 2rem 1.8rem;

  h4 {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
  }

  &:before {
    content: '';
    background: url(${CheckIcon}) top center no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    margin: 0 0.5rem 0 -0.25rem;
    top: 3px;
    position: relative;
  }
`

const UnorderedList = ({ props }) => {
  return (
    <UlStyle>
      {props.length &&
        props.map(item => {
          return (
            <LiStyle key={item}>
              <h4>{item}</h4>
            </LiStyle>
          )
        })}
    </UlStyle>
  )
}

export default UnorderedList
