import React from "react"
import styled from "styled-components"

const Symbol = styled.div`
  font-size 118px;
  line-height: .7;
  left: 30px;

  @media (min-width: 768px) {
    font-size: 140px;
    left: 0;
  }
`

const BackgroundSymbol = ({ backgroundSymbol }) => {
  return (
    <Symbol
      style={{
        position: "absolute",
        top: 0,
        color: "#E0E0E0",
        textAlign: "right",
        fontFamily: "Roboto, sans-serif",
        fontWeight: 800,
        zIndex: 1,
      }}
    >
      {backgroundSymbol}
    </Symbol>
  )
}

export default BackgroundSymbol
