import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Divider from "@mui/material/Divider";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import LinkBlock from "../components/atoms/links/LinkBlock";
import HeroImage from "../components/organisms/hero-image/HeroImage";
import Accordion from "../components/organisms/accordion/Accordion";
import List from "../components/organisms/lists/List";
import BackgroundSymbol from "../components/organisms/BackgroundSymbol";
import { richTextRenderOptions } from "../helpers/constants";
import HeadingDynamic from "../components/atoms/typography/HeadingDynamic";
import { backgroundColors } from "../styled/colors";
import { spacing } from "../styled/spacing";
import UnorderedList from "../components/organisms/lists/UnorderedList";
import FormComponent from "../components/organisms/form/Form";
import Carousel from "../components/Carousel";
import SearchSimple from "../components/organisms/search/SearchSimple";
import SearchMain from "../components/organisms/search/SearchMain";
import ArticleCard from "../components/organisms/article-card/ArticleCard";

const propTypes = {
  data: PropTypes.object.isRequired,
};

const LayoutCopyComponent = (param) => {
  switch (param.visualStyle) {
    case "Left heading":
      var headingStyle = null;
      if (param.backgroundSymbol !== null)
        headingStyle = {
          paddingTop: 30,
        };
      return (
        <div className="row">
          <div className="col-md-6">
            {param.subHeading !== null ? (
              <div
                style={{
                  textTransform: "uppercase",
                  position: "relative",
                  zIndex: "10",
                }}>
                {param.subHeading}
              </div>
            ) : null}
            {param.backgroundSymbol && (
              <BackgroundSymbol backgroundSymbol={param.backgroundSymbol} />
            )}
            <HeadingDynamic
              level={param.headingLevel}
              className="heading--spacing"
              style={{
                marginLeft: param.backgroundSymbol ? "2.5rem" : "",
                position: "relative",
                zIndex: 1,
                ...headingStyle,
              }}>
              {param.headline}
            </HeadingDynamic>
          </div>
          <div className="col-md-6">
            {renderRichText(param.copyRich, richTextRenderOptions)}
          </div>
        </div>
      );
    default:
      return (
        <>
          <div className="container section--spacing">
            <HeadingDynamic
              className="heading--spacing text-center"
              level={param.headingLevel}>
              {param.headline}
            </HeadingDynamic>
            <div>{renderRichText(param.copyRich, richTextRenderOptions)}</div>
            {param.ctaTitle && (
              <div className="col-md-12 text-center">
                <a href={param.ctaLink} className="button button--primary">
                  {param.ctaTitle}
                </a>
              </div>
            )}
          </div>
        </>
      );
  }
};
class PageTemplate extends React.Component {
  renderSwitch(param) {
    switch (param.__typename) {
      case "ContentfulLayoutSection":
        return (
          <div
            style={{
              backgroundColor:
                param.backgroundColor &&
                backgroundColors[param.backgroundColor],
              paddingTop:
                param.verticalSpacing && spacing[param.verticalSpacing],
              paddingBottom:
                param.verticalSpacing && spacing[param.verticalSpacing],
            }}>
            {param.headline ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <HeadingDynamic
                      className="heading__section"
                      level={param.headingLevel}>
                      {param.headline}
                    </HeadingDynamic>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="container">
              {param.references &&
                param.references.map((item, index) => {
                  switch (item.__typename) {
                    case "ContentfulLayoutCopy":
                      return <LayoutCopyComponent key={index} {...item} />;

                    case "ContentfulLayoutCards":
                      return (
                        <div className="row" key={index}>
                          {item && <Carousel items={item?.cards} />}
                        </div>
                      );
                    case "ContentfulLayoutForm":
                      return (
                        <div
                          className="row"
                          key={index}
                          style={{ alignItems: "start" }}>
                          <div className="col-md-6">
                            <HeadingDynamic
                              level="2"
                              className="content--spacing"
                              style={{
                                marginLeft: item.backgroundSymbol
                                  ? "2.5rem"
                                  : "",
                                position: "relative",
                              }}>
                              {item.headline}
                            </HeadingDynamic>
                            {renderRichText(
                              item.copyRich,
                              richTextRenderOptions
                            )}
                          </div>
                          <div className="col-md-6">
                            <FormComponent uploadText={item.uploadFieldText} />
                          </div>
                        </div>
                      );

                    case "ContentfulLayoutCalculator":
                      switch (item.visualStyle) {
                        case "Vertical":
                          return (
                            <div className="row">
                              <div className="col-md-6">
                                <div className="max-width--md">
                                  <h2 className="heading-1 no-margin">
                                    {item.headline}
                                  </h2>
                                </div>
                                {/* {renderRichText(param.content, richTextRenderOptions)} */}
                                <div className="max-width--md">
                                  <UnorderedList
                                    props={[
                                      "Сравнете оферти",
                                      "Изберете доставчик",
                                      "Заявете договор",
                                    ]}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <SearchMain />
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div className="row" key={index}>
                              <div className="col-md-2"></div>
                              <div className="col-md-12">
                                <SearchSimple />
                              </div>
                            </div>
                          );
                      }

                    default:
                      return null;
                  }
                })}
            </div>
          </div>
        );

      case "ContentfulLayoutCalculator":
        switch (param.visualStyle) {
          case "Vertical":
            return (
              <div className="row">
                <div className="col-md-6">
                  <h2 className="heading-1">{param.headline}</h2>
                  {/* {renderRichText(param.content, richTextRenderOptions)} */}
                  <UnorderedList
                    props={[
                      "Сравнете оферти",
                      "Изберете доставчик",
                      "Заявете договор",
                    ]}
                  />
                </div>

                <div className="col-md-6">
                  <SearchMain />
                </div>
              </div>
            );
          default:
            return (
              <section className="section--spacing">
                <div className="container section--spacing">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-12">
                      <SearchSimple />
                    </div>
                  </div>
                </div>
              </section>
            );
        }
      case "ContentfulLayoutHeroImage":
        return (
          <div>
            <HeroImage
              headline={param?.headline}
              subheader={param.subHeading}
              paragraph={param.paragraph}
              actionText={param.ctaTitle}
              actionLink={param.ctaLink}
              backgroundImage={param.backgroundImage.gatsbyImageData}
              showLogo={param?.showLogo}
              height={param?.height}
              align={param?.align}
            />
          </div>
        );

      case "ContentfulLayoutCopy":
        const [layout = { article: [] }] = param.layout || [];
        const [article] = layout?.article || [];
        const meta = [
          {
            property: "og:image",
            content: layout?.openGraphImage?.url || article?.image?.url,
          },
          {
            property: "og:description",
            content: layout?.metaDescription || article?.summary?.summary,
          },
        ];
        return (
          <>
            <Seo
              meta={meta}
              title={param.headline}
              description={layout?.metaDescription || article?.summary?.summary}
            />
            <LayoutCopyComponent {...param} />
          </>
        );

      case "ContentfulLayoutQA":
        return (
          <section className="container section--spacing">
            <div className="row">
              <div className="col-md-12">
                <h2 className="heading--spacing text-center">
                  {param.headline}
                </h2>
                <Accordion
                  className="content--spacing"
                  items={param.questionsAndAnswers}
                />

                <div>
                  {param?.seeMoreAction && (
                    <LinkBlock
                      icon
                      link={param.seeMoreAction}
                      text={param.seeMoreText}
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        );

      case "ContentfulLayoutDistributors":
        return (
          <>
            <section className="container section--spacing">
              <div className="row">
                <div className="col-md-8">
                  <h2>{param.headline}</h2>
                </div>
              </div>
            </section>
            <Divider />
            <section className="container section--spacing">
              <div className="row">
                <div className="col-md-8">
                  <List
                    className="content--spacing"
                    icon
                    items={param?.distributors}
                  />
                  <div className="float-right ">
                    {param?.seeMoreAction && (
                      <LinkBlock
                        icon
                        link={param.seeMoreAction}
                        text={param.seeMoreText}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </>
        );
      case "ContentfulLayoutArticles":
        return (
          <div className="container section--spacing">
            <div className="row">
              <div className="col-md-12">
                <h2 className="heading--spacing text-center">{param.title}</h2>
                {param.articles.map((article, index) => {
                  return (
                    <ArticleCard
                      key={index}
                      to={`/${article.layout.slug}`}
                      date={article.date ? article.date : ""}
                      hasImage={article.image != null}
                      image={article.image}
                      //                      src={article.image != null ? article.image.fluid.src : ""}
                      title={article.title}
                      summary={article.summary ? article.summary.summary : ""}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    const layout = this.props.data.contentfulLayout;
    const { title, metaDescription, contentModules } = layout;

    return (
      <Layout>
        <Seo title={title} description={metaDescription} />
        {contentModules.map((module, i) => (
          <div key={i}>{this.renderSwitch(module)}</div>
        ))}
      </Layout>
    );
  }
}

PageTemplate.propTypes = propTypes;

export default PageTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    contentfulLayout(id: { eq: $id }, node_locale: { eq: "bg" }) {
      contentModules {
        ...LayoutSection
        ...LayoutCopy
        ...LayoutDistributors
        ...LayoutQA
        ...LayoutHeroImage
        ...LayoutCalculator
        ...LayoutArticles
      }
      id
      title
      metaDescription
      openGraphImage {
        url
        title
      }
    }
  }
`;
