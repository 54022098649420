import * as React from "react"
import { useState } from "react"
import styled from "styled-components"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination } from "swiper"

import useWindowSize from "../../helpers/useWindowSize"
import Card from "../../components/organisms/card/Card"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
// install Swiper modules
SwiperCore.use([Pagination])

const swiperSettingsDesktop = {
  slidesPerView: 3,
  spaceBetween: 16,
  speed: 700,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  navigation: true,
}

const swiperSettingsTablet = {
  slidesPerView: 2,
  spaceBetween: 16,
  speed: 300,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  pagination: {
    clickable: true,
  },
}

const swiperSettingsMobile = {
  slidesPerView: 1,
  speed: 300,
  spaceBetween: 16,
  observer: true,
  observeParents: true,
  resizeObserver: true,
  pagination: {
    clickable: true,
  },
}

const SwiperContainer = styled(Swiper)`
  .swiper-wrapper {
    margin-bottom: 3rem;
  }
  .swiper-slide {
    height: auto;
  }

  .swiper-pagination-bullet-active {
    background: var(--secondary-color);
  }
`

const Carousel = ({ items }) => {
  const [isDesktop, setDesktop] = useState(true)
  const [isTablet, setTablet] = useState(false)

  const [windowWidth] = useWindowSize()

  React.useEffect(() => {
    if (windowWidth >= 1300) {
      setDesktop(true)
      setTablet(false)
    }
    if (windowWidth <= 1300 && windowWidth >= 768) {
      setDesktop(false)
      setTablet(true)
    }
    if (windowWidth < 768) {
      setDesktop(false)
      setTablet(false)
    }
  }, [windowWidth])
  console.log(isDesktop)
  console.log(isTablet)

  const list = () => {
    return items.map((card, index) => {
      return (
        <SwiperSlide key={index}>
          <Card
            title={card?.headline}
            iconUrl={card.icon?.file.url}
            content={card?.content}
            backgroundColor={card?.background}
          />
        </SwiperSlide>
      )
    })
  }

  return items.length > 0 ? (
    <div className="col-md-12">
      {!isDesktop && !isTablet && (
        <SwiperContainer {...swiperSettingsMobile}>{list()}</SwiperContainer>
      )}

      {isTablet && (
        <SwiperContainer {...swiperSettingsTablet}>{list()}</SwiperContainer>
      )}

      {isDesktop && (
        <SwiperContainer {...swiperSettingsDesktop}>{list()}</SwiperContainer>
      )}
    </div>
  ) : null
}

export default Carousel
