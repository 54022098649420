import React, { useState, useRef } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { richTextRenderOptions } from "../../../helpers/constants"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"

import colors from "../../../styled/colors"
import "./accordion.css"

// TODO: Use MUI Accordion component - https://v4.mui.com/components/accordion/#accordion
const Accordion = ({ className = "", items, preSelectedIndex = null }) => {
  const [activeIndex, setActiveIndex] = useState(preSelectedIndex)
  const [activeIndexes, setActiveIndexes] = useState(
    preSelectedIndex == null ? [] : [preSelectedIndex]
  )

  const handleChange = index => {
    setActiveIndex(activeIndex === index ? null : index)

    let array = activeIndexes
    let findIndex = array.indexOf(index)

    if (findIndex !== -1) {
      array = array.length > 1 ? array.filter(el => el !== index) : []
    } else {
      array.push(index)
    }

    setActiveIndexes([...array])
  }

  const content = useRef(null)
  //const itemScope = "itemscope"

  const renderedItems = items.map((item, index) => {
    let isActive = activeIndexes.indexOf(index) === -1 ? false : true

    let activeClass = isActive ? "active" : ""
    let setHeight = isActive ? `100%` : "0px"
    const itm = item.node ? item.node : item
    return (
      <div
        className="accordion__section"
        key={`${index}-${itm.qestion || itm.qestion}}`}
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <button
          className={`${activeClass} accordion__header`}
          onClick={() => handleChange(index)}
        >
          {isActive ? (
            <RemoveCircleOutlineIcon
              style={{
                color: colors.secondary,
                fontSize: "2rem",
                margin: "0 1rem 0 0",
              }}
            />
          ) : (
            <AddCircleOutlineIcon
              style={{
                color: colors.secondary,
                fontSize: "2rem",
                margin: "0 1rem 0 0",
              }}
            />
          )}
          <h4 className="accordion__title" itemProp="name">
            {itm.qestion}
          </h4>
        </button>
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion__content"
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div className="accordion__text" itemProp="text">
            {itm.answer && renderRichText(item.answer, richTextRenderOptions)}
          </div>
        </div>
      </div>
    )
  })
  return <div className={`accordion ${className}`}>{renderedItems}</div>
}

export default Accordion
