import React, { useState } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Operators from "../../atoms/select/Operators";
import Button from "../../atoms/buttons/Button";
import colors from "../../../styled/colors";
import * as constants from "../../../helpers/constants";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { fireGAEvent } from "../../../helpers/ga";
import { MONEY_TO_KW_MULTIPLIER } from "../../../helpers/constants";
import roundNumber from "../../../helpers/roundNumber";

import { SEARCH_SIMPLE_CONSUMATION_TEXT } from "../../../helpers/constants";

const BLANK_CONSUMPTION_LV = "---";

const ItemLink = styled.a`
  align-items: start;
  margin-bottom: 30px;

  :hover {
    border-bottom: none;
  }
`;

const formAction = "/electricity-meter/";
//const MONTHLY_FIELD_LBL = "лв./ мес"

const calculatorSwitch = (e) => {
  e.preventDefault();
  const boxContainers = document.getElementsByClassName("switch-box");
  for (let index = 0; index < boxContainers.length; index++) {
    const box = boxContainers[index];
    box.style.display = box.style.display === "none" ? "block" : "none";
  }
};

const SearchMain = () => {
  const [state, setState] = useState({
    consumption: "",
    consumptionlv: "",
    operator: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "consumption": {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          consumptionlv: BLANK_CONSUMPTION_LV,
        }));

        break;
      }

      case "consumptionlv": {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
          consumption: roundNumber(value * MONEY_TO_KW_MULTIPLIER),
        }));

        break;
      }

      default: {
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));

        break;
      }
    }
  };

  return (
    <Box
      sx={{
        borderTop: 5,
        borderColor: colors.secondary,
        boxShadow: 2,
        borderRadius: 1,
        bgcolor: "background.paper",
        px: 4,
        py: 3,
      }}
    >
      <form action={formAction} method="GET" id="compare">
        <div className="row justify-content-center switch-box">
          <div className="col-md-12 element--spacing-mobile">
            <h4 className="text-uppercase element--spacing">
              Потърсете оферти за ток
            </h4>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="consumptionMain">
                Консумация на ток
              </InputLabel>
              <OutlinedInput
                name="consumption"
                id="consumption"
                value={state.consumption > 0 ? state.consumption : ""}
                type="number"
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">кВтч./мес.</InputAdornment>
                }
                label="Консумация на ток"
                placeholder="Консумация на ток"
                sx={{
                  "input::-webkit-outer-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </FormControl>
            <ItemLink
              href={"#"}
              className="list__link text-underline"
              onClick={calculatorSwitch}
            >
              Не знам каква е консумацията
            </ItemLink>
          </div>
          <div className="col-md-12">
            <Operators hasTitle={false} />
          </div>
          <div className="col-md-12 d-flex">
            <Button
              className="flex-grow-1"
              primary
              type="submit"
              label={constants.LBL_SEARCH_BUTTON_TEXT}
              size="small"
              style={{ width: "100%" }}
              onClick={() => fireGAEvent("offer_search_btn_clicked")}
            />
          </div>
        </div>

        <div
          className="row justify-content-center switch-box"
          style={{ display: "none" }}
        >
          <div className="col-md-12 element--spacing-mobile">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h4 className="heading-4 text-uppercase element--spacing">
                Калкулатор
              </h4>
              <ItemLink
                href={"#"}
                className="list__link text-underline"
                onClick={calculatorSwitch}
              >
                Откажи
              </ItemLink>
            </div>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="consumption">Сметка за ток</InputLabel>
              <OutlinedInput
                name="consumptionlv"
                id="consumptionlv"
                value={state.consumptionlv > 0 ? state.consumptionlv : ""}
                onChange={handleChange}
                type="number"
                endAdornment={
                  <InputAdornment position="end">лв./мес.</InputAdornment>
                }
                label="Сметка за ток"
                placeholder="Сметка за ток"
                sx={{
                  "input::-webkit-outer-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input::-webkit-inner-spin-button": {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                  "input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
              />
            </FormControl>
            <div style={{ margin: "20px 50px 20px 0px", textAlign: "left" }}>
              <p>{SEARCH_SIMPLE_CONSUMATION_TEXT}</p>
            </div>
            <Button
              primary
              type="submit"
              label="Изчисли консумацията"
              onClick={calculatorSwitch}
              size="small"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </form>
    </Box>
  );
};

export default SearchMain;
