import React from "react"

const HeadingDynamic = props => {
  const CustomHeading = `h${props.level}`
  return (
    <CustomHeading style={props.style} className={props.className}>
      {props.children}
    </CustomHeading>
  )
}

export default HeadingDynamic
