import React from "react"
import styled from "styled-components"

import Image from "../../atoms/image/Image"
import { paths } from "../../../helpers/constants"
import DistributorIcon from "../../../images/distributor-icon.png"
import "./lists.css"

const ListItems = styled.div`
  display: flex;
  flex-direction: column;
`
const ListItemBlock = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 2rem;
  text-align: left;
`

const ItemLink = styled.a`
  display: flex;
  flex-direction: row !important;
  width: 100%;
  text-align: top !important;
  align-items: start !important;
`

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 1rem;
`

const ListTitle = styled.h4``

const IconStyle = styled.div`
  .image {
    margin-bottom: 0;
  }
`

const List = ({ className = "", icon = false, items = [] }) => {
  return (
    <ListItems className={`${className} list list--distributor`}>
      {items.map((item, index) => {
        return (
          <ListItemBlock key={`${item?.slug}-${index}`} className="list__item">
            <ItemLink
              href={paths.distributors.link + item?.slug || ""}
              className="list__link"
            >
              {icon && (
                <IconStyle className="list__bullet">
                  <Image src={DistributorIcon} />
                </IconStyle>
              )}
              <ListContent>
                <ListTitle> {item?.title || ""}</ListTitle>
              </ListContent>
            </ItemLink>
          </ListItemBlock>
        )
      })}
    </ListItems>
  )
}

export default List
